import { useEffect } from 'react';
import { GetCookie, SetCookie } from './cookies';

// Initialize global variables on the window object
const initializeGlobalVariables = () => {
  window.mxname = window.mxname || "";
  window.mxuserEmail = window.mxuserEmail || "";
  window.mxuserPlan = window.mxuserPlan || "";
  window.mxcurrency = window.mxcurrency || "";
  window.mxdebugMode = window.mxdebugMode || "";
  window.mxamount = window.mxamount || "";
  window.mxkeyword = window.mxkeyword || "";
  window.mxrewardfulVia = window.mxrewardfulVia || "";
  window.mxemailid = window.mxemailid || "";
  window.mxadid = window.mxadid || "";
  window.mxppg = window.mxppg || "";
  window.mxpmt = window.mxpmt || "";
  window.mxhowdoiplantouse = window.mxhowdoiplantouse || "";
  window.mxremakemedloption = window.mxremakemedloption || "";
  window.mxtheme = window.mxtheme || "";
  window.mxlocales = window.mxlocales || "";
  window.gaw_currency = window.gaw_currency || "";
  window.mxtransactionid = window.mxtransactionid || "";
};

export default function ClientComponent() {
  useEffect(() => {
    initializeGlobalVariables(); // Initialize global variables

    const baseDomain = window.location.hostname;
    const urlParams = new URLSearchParams(window.location.search);
    const paramsToSet = ['name', 'user_email', 'user_plan', 'currency', 'via', 'price', 'keyword', 'emailid', 'adid', 'ppg', 'pmt', 'mode'];

    paramsToSet.forEach(param => {
      const paramValue = urlParams.get(param);
      if (paramValue) {
        SetCookie(param, paramValue, 30);  // Assuming SetCookie is available globally or imported
      }
    });

    window.mxname = baseDomain ?? "coding.ai-pro.org";
    window.mxdebugMode = "false";
    window.mxuserEmail = GetCookie('user_email') ?? "";
    window.mxuserPlan = GetCookie('user_plan') ?? "";
    window.mxrewardfulVia = GetCookie('via') ?? "";
    window.mxcurrency = GetCookie('currency') ?? "USD";
    window.mxamount = GetCookie('amount') ?? "0";
    window.mxkeyword = GetCookie('keyword') ?? "";
    window.mxemailid = GetCookie('emailid') ?? "";
    window.mxadid = GetCookie('adid') ?? "";
    window.mxppg = GetCookie('ppg') ?? "";
    window.mxpmt = GetCookie('pmt') ?? "";
    window.mxhowdoiplantouse = GetCookie('howdoiplantouse') ?? "";
    window.mxremakemedloption = GetCookie('remakemedloption') ?? "";
    window.mxtheme = GetCookie('kt8typtb') ?? "arcana";
    window.mxlocales = GetCookie('locales') ?? "en";
    window.gaw_currency = GetCookie('currency') ?? "USD";
    window.mxtransactionid = GetCookie('jewelStart') ?? "";
  }, []);

  return null;
}